<template>
  <img
    alt="available service"
    :src="props.url"
    class="rounded-circle"
    style="width: 250px; height: 250px; object-fit: cover; object-position: 65% 0"
    :style="{ objectPosition: props.objectPosition }"
  />
  <div class="text-h5 text-accent">{{ props.text }}</div>
</template>

<script setup>
const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  objectPosition: {
    type: String,
    required: true,
  },
});
</script>
